.faq {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    max-width: 800px;
    margin: 20px auto;
}

.title.withFilter {
    margin-bottom: 36px;
}

.listWrapper {
    border-top: 2px solid white;
    border-bottom: 2px solid white;
}

.filterGroup {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-bottom: 40px;
}

@media (max-width: 600px) {
    .filterGroup {
        margin-bottom: 20px;
    }
}

.filter {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;

    width: 120px;
    height: 40px;
    border-radius: 40px;
    border: 2px solid white;

    color: white;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.05em;

    margin: 0 12px;
    cursor: pointer;

    transition: 0.3s ease-in-out;
}

@media (max-width: 600px) {
    .filter {
        width: 80px;
        height: 28px;
        font-size: 12px;
        font-weight: 500;
        margin: 0 4px;
    }
}

.filter.active,
.filter:hover {
    background: white;
    font-weight: 700;
    color: #5a6aed;
}

.itemWrapper:not(:last-child) {
    border-bottom: 2px solid white;
}

.more {
    color: white;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.05em;
    text-align: center;
    cursor: pointer;
    margin: 40px auto;
    text-decoration: underline;
    text-underline-offset: 6px;
}
