.gameIntro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 50px 20px;
}

.content {
    color: #fff;
    font-size: 18px;
    font-style: normal;
    font-weight: 600;
    line-height: 180%; /* 23.8px */
    letter-spacing: 0.03em;
    margin: 48px auto 80px;
}

.itemWrapper {
    display: flex;
    justify-content: center;
}

@media (max-width: 1000px) {
    .itemWrapper {
        flex-direction: column;
    }
}

.item {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 36px;
    max-width: 316px;
}

@media (max-width: 1000px) {
    .item {
        padding: 36px 0;
    }
}

@media (max-width: 800px) {
    .item {
        max-width: 260px;
    }
}

.item > .icon {
    width: 180px;
    margin-bottom: 12px;
}

.item > .icon > img {
    width: 100%;
}

.item > .label {
    color: #fff;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 180%; /* 23.8px */
    letter-spacing: 0.03em;
    margin-bottom: 16px;
}

@media (max-width: 800px) {
    .item > .label {
        font-size: 16px;
        margin-bottom: 12px;
    }
}

.item > .context {
    color: #fff;
    font-size: 15px;
    font-style: normal;
    text-align: center;
    font-weight: 500;
    line-height: 180%; /* 23.8px */
    letter-spacing: 0.03em;
}

@media (max-width: 800px) {
    .item > .context {
        font-size: 13px;
    }
}

.btn {
    margin-top: 70px;
}

@media screen and (max-width: 1000px) {
    .btn {
        margin-top: 40px;
    }
}
