.tabs {
    display: flex;
    align-items: center;
    justify-content: center;
    color: white;
}

.tab {
    color: white;
    font-size: 13.5px;
    font-weight: 500;
    letter-spacing: 0.05em;
    line-height: 200%;
    width: 100px;
    text-align: center;
    border-bottom: 2px solid white;
    padding: 2px 10px;
    cursor: pointer;
}

.tab:not(.active) {
    opacity: 0.6;
}
