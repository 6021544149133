.purchaseProcress {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.itemWrapper {
    display: flex;
    justify-content: center;
    margin-bottom: 100px;
}

@media (max-width: 1000px) {
    .itemWrapper {
        flex-direction: column;
        margin-bottom: 60px;
    }
}

.item {
    margin: 0 20px;
}

@media (max-width: 1000px) {
    .item {
        margin: 12px 0;
    }
}

@media (max-width: 1000px) {
    .item:first-child {
        margin-top: 0;
    }
}

.item .icon {
    width: 180px;
    height: 180px;
}

.item .icon > img {
    width: 100%;
    height: 100%;
}

.item .label {
    color: #fff;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 180%; /* 23.8px */
    letter-spacing: 0.03em;
    text-align: center;
}

.item .note {
    color: #fff;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: 180%; /* 23.8px */
    letter-spacing: 0.03em;
    text-align: center;
}

.label {
    color: #fff;
    font-size: 17px;
    font-style: normal;
    font-weight: 600;
    line-height: 180%; /* 23.8px */
    letter-spacing: 0.03em;
    text-align: center;
    /* margin-bottom: 2px; */
}

@media (max-width: 800px) {
    .label {
        font-size: 16px;
    }
}

.caution {
    color: #fff;
    font-size: 15px;
    font-style: normal;
    font-weight: 600;
    line-height: 180%; /* 23.8px */
    letter-spacing: 0.03em;
    max-width: 590px;
    margin: 0 auto;
    width: 100%;
    padding: 0 40px 0 60px;
}

@media (max-width: 800px) {
    .caution {
        font-size: 13px;
    }
}

.caution li {
    /* margin-bottom: 16px; */
}

.more {
    color: white;
    font-size: 14px;
    font-weight: 600;
    line-height: normal;
    letter-spacing: 0.05em;
    text-align: center;
    cursor: pointer;
    margin: 40px auto 80px;
    text-decoration: underline;
    text-underline-offset: 6px;
}

@media (max-width: 1000px) {
    .more {
        margin: 20px auto 40px;
    }
}

@media (max-width: 800px) {
    .more {
        font-size: 13px;
    }
}
