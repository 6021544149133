.title {
    color: white;
    font-family: Inter, 'Noto Sans', 'Noto Sans TC', 'PingFangTC', 'Microsoft JhengHei';
    font-size: 24px;
    font-weight: 600;
    text-align: center;
    line-height: normal;
    letter-spacing: 0.05em;

    margin-bottom: 52px;
}

@media (max-width: 600px) {
    .title {
        font-size: 20px;
        margin-bottom: 20px;
    }
}
