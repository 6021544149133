.staticIntro {
    display: flex;
    align-items: center;
    padding: 80px 40px 160px;
    width: 100vw;
    height: 100vh;
}

.text {
    color: white;
    font-size: 15px;
    font-weight: 600;
    line-height: 180%; /* 28.8px */
    letter-spacing: 0.05em;
    max-width: 400px;
    margin: 0 auto;
}
