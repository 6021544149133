.button {
    display: inline-flex;
    justify-content: center;
    align-items: center;
    height: 44px;
    padding: 0 40px;

    border-radius: 22px;
    background: #303dec;
    color: #fff;
    text-align: center;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    line-height: 1;
    letter-spacing: 2.4px;
    min-width: 180px;
    cursor: pointer;

    transition: 0.3s ease-in-out;

    flex-shrink: 0;
}

@media (max-width: 800px) {
    .button {
        height: 36px;
        font-size: 14px;
        padding: 0 28px;
        min-width: 140px;
    }
}

.disabled {
    cursor: not-allowed;
    color: rgba(255, 255, 255, 0.6);
    background: #7a85da;
}

@keyframes shake {
    0% {
        transform: translate(0px, 0px) rotate(0deg);
    }
    6% {
        transform: translate(3px, -2px) rotate(2.5deg);
    }
    12% {
        transform: translate(-0.5px, -1px) rotate(-2.5deg);
    }
    18% {
        transform: translate(3px, -2px) rotate(2.5deg);
    }
    24% {
        transform: translate(-0.5px, -1px) rotate(-2.5deg);
    }
    32% {
        transform: translate(0px, 0px) rotate(0deg);
    }
}

.button.shake:not(.disabled):hover {
    animation-name: shake;
    animation-duration: 2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
    animation-delay: 0s;
}

.button.color:not(.disabled):hover {
    background-color: #3f4dbc;
}
