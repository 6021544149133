.shopFixedLink {
    position: fixed;
    bottom: 20px;
    right: 20px;
    transition: 0.5s ease-in-out;
    cursor: pointer;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    z-index: 3;
}

@media (max-width: 600px) {
    .shopFixedLink {
        right: 12px;
    }
}

.shopFixedLink:not(.active) {
    bottom: -80px;
    opacity: 0;
}

@keyframes bounce {
    0% {
        transform: rotate(0deg) translateY(0);
    }
    50% {
        transform: rotate(0deg) translateY(-10px);
    }
    100% {
        transform: rotate(0deg) translateY(0);
    }
}

.shopFixedLink:hover > .land {
    animation-name: bounce;
    animation-duration: 1.2s;
    animation-iteration-count: infinite;
    animation-timing-function: ease-in-out;
}

.land {
    width: calc(150px * 0.25);
    height: calc(330px * 0.25);
    /* transform: rotate(30deg); */
    /* filter: brightness(1.2); */
}

.land > img {
    width: 100%;
}

.label {
    color: #fff;
    font-size: 13px;
    font-weight: 700;
    line-height: 130%; /* 46.8px */
    letter-spacing: 0.05em;
    margin-top: 12px;
    text-align: center;

    display: flex;
    justify-content: center;
    align-items: center;
    background: white;
    height: 28px;
    border-radius: 40px;
    padding: 0 16px;
    color: #0a0b16;
}
