.routeIntro {
    position: relative;
    display: flex;
    width: 520px;
    justify-content: space-between;
    align-items: flex-end;
    font-family: Inter, 'Noto Sans', 'Noto Sans TC', 'PingFangTC', 'Microsoft JhengHei';
}

@media (max-width: 600px) {
    .routeIntro {
        flex-direction: column;
        justify-content: center;
        align-items: center;
        width: 100vw;
    }
}

@media (min-width: 601px) {
    .container {
        position: relative;
        display: flex;
        width: 520px;
        justify-content: space-between;
        align-items: flex-end;
        font-family: Inter, 'Noto Sans', 'Noto Sans TC', 'PingFangTC', 'Microsoft JhengHei';
    }
}

.title {
    color: #fff;
    font-size: 24px;
    font-weight: 600;
    line-height: 130%; /* 46.8px */
    letter-spacing: 0.125em;
    margin-bottom: 20px;
}

@media (max-width: 600px) {
    .title {
        font-size: 20px;
        text-align: center;
        margin-bottom: 16px;
    }
}

@media (max-width: 600px) {
    .right {
        align-items: center;
        justify-content: center;
    }
}

.content {
    color: #fff;
    font-size: 14.5px;
    font-weight: 600;
    line-height: 170%; /* 23.8px */
    letter-spacing: 0.05em;
    white-space: pre-line;
    margin-bottom: 88px;
}

@media (max-width: 600px) {
    .content {
        font-size: 13px;
        margin-bottom: 28px;
    }
}

.content.withMore {
    margin-bottom: 60px;
}

@media (max-width: 600px) {
    .content.withMore {
        margin-bottom: 20px;
    }
}

.more {
    color: #fff;
    font-size: 12px;
    font-style: normal;
    font-weight: 500;
    line-height: 130%; /* 15.6px */
    letter-spacing: 0.1em;

    text-decoration: underline;
    text-underline-offset: 8px;

    cursor: pointer;

    margin-top: 16px;
    margin-bottom: 12px;
}

@media (max-width: 600px) {
    .more {
        text-align: center;
        margin-top: 12px;
        margin-bottom: 0;
        /* font-weight: 600; */
        /* color: #3b3e57; */
        font-weight: 700;
        /* color: #262c54; */
        text-underline-offset: 4px;
    }
}

.infoWrapper {
    color: #fff;
    font-size: 14px;
    font-weight: 600;
    line-height: 170%; /* 23.8px */
    letter-spacing: 1.4px;
    margin-top: 50px;
}

@media (max-width: 600px) {
    .infoWrapper {
        display: flex;
        flex-direction: column;
        justify-content: center;
        font-size: 13px;
        margin-top: unset;
    }
}

.infoItem {
    display: flex;
    width: 226px;
    padding: 6px 0;
    border-bottom: 1px solid white;
}

@media (max-width: 600px) {
    .infoItem {
        width: 100%;
        border-bottom: none;
        /* justify-content: center; */
        padding: 0;
    }
}

.infoItem > .label {
    width: 120px;
}

.infoItem > .value {
    white-space: pre-line;
}

@media (max-width: 600px) {
    .infoItem > .label {
        width: unset;
        margin-right: 0.5em;
    }
}

.infoItem > .label.mobile::after {
    content: ' : ';
}

.btn {
    margin-top: 48px;
}

@media (max-width: 600px) {
    .btn {
        margin-top: 20px;
        text-align: center;
        width: calc(100% - 40px);
        margin: 28px 20px 0;
    }
}

.btn > .icon {
    margin-right: 8px;
}
