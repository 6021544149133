.titleAlone {
    position: fixed;
    width: 140px;
    top: 45px;
    left: calc(50vw - 65px);
    cursor: pointer;
    z-index: 10;
}

.titleAlone > img {
    width: 100%;
}

.header {
    position: fixed;
    left: 0;
    display: flex;
    width: 100%;
    padding: 20px 40px;
    justify-content: space-between;
    align-items: center;
    z-index: 10;
    /* background-color: #5a6aed; */
    background: linear-gradient(180deg, #697df1, transparent);
}

@media (max-width: 1200px) {
    .header {
        padding: 20px;
    }
}

.title {
    width: 130px;
    cursor: pointer;
    filter: brightness(1.5);
}

@media (max-width: 1200px) {
    .title {
        width: 100px;
    }
}

.title > img {
    width: 100%;
}

.nav {
    display: flex;
    color: white;
}

.navItem {
    color: #fff;
    font-family: Inter, 'Noto Sans', 'Noto Sans TC', 'PingFangTC', 'Microsoft JhengHei', sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 180%;
    letter-spacing: 0.05em;
    flex-shrink: 0;
    cursor: pointer;
}

.navItem:not(:last-child) {
    padding-right: 32px;
}

.navItem.active {
    color: #c3ff9c;
}

.menuIcon {
    position: relative;
    cursor: pointer;
    z-index: 20;
}
