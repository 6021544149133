.enterStatic {
    position: absolute;
    top: 0;
    left: 50vw;
    transform: translateX(-50%);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding-top: 80px;
    overflow: hidden;
}

.title {
    width: calc(190px * 1.2);
    height: calc(55px * 1.2);
    line-height: 1.15;
}

.subtitle {
    color: #fff;
    text-align: center;
    font-size: 13px;
    font-weight: 600;
    line-height: 1.15;
    letter-spacing: 2.4px;
    margin-top: 12px;
}

.btn {
    margin-top: 20px;
    height: 36px;
    font-size: 14px;
    padding: 0 28px;
    min-width: 140px;
}

.landWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    pointer-events: none;
    margin-top: 20px;
}

.landSrc {
    width: calc(100vw - 40px);
    max-width: 400px;
}

.landSrc > img {
    width: 100%;
}
