.pageLayout {
    position: fixed;
    left: 0;
    background-color: #5a6aed;
    padding: 80px 40px 120px;
    z-index: 100;

    overflow-y: auto;
}

@media (max-width: 600px) {
    .pageLayout {
        padding: 80px 20px;
    }
}

.close {
    position: fixed;
    left: 20px;
    color: white;
    cursor: pointer;
}
