.mainStatic {
}

.routeIntro {
    width: calc(100% - 60px);
    margin: 0 auto;
}

.howto,
.purchase,
.faq,
.contact {
    padding-top: 120px;
    padding-bottom: 120px;
}

.faq {
    padding-left: 20px;
    padding-right: 20px;
}

.credits {
}
