.lock {
    width: 300px;
}

@media (max-width: 600px) {
    .lock {
        transform: scale(1.5);
        transform-origin: center;
    }
}

.lock > img {
    width: 100%;
}
