.scroll {
    position: fixed;
    pointer-events: none;
    z-index: 10;
    bottom: 16px;
    left: 50vw;
    transform: translateX(-50%);

    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 12px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.6px;
    padding-right: 12px;
}

.emptySection {
    position: relative;
    width: 100vw;
    height: 100vh;
    pointer-events: none;
}

.intro {
    position: fixed;
    top: 132px;
    right: 128px;
    width: 400px;
    color: white;
    font-size: 15px;
    font-weight: 600;
    line-height: 180%; /* 28.8px */
    letter-spacing: 0.05em;
}

@media (max-width: 1200px) {
    .intro {
        right: 80px;
    }
}

@media (max-width: 600px) {
    .intro {
        top: 100px;
        right: 30px;
        width: calc(100% - 60px);
    }
}

.routeIntro {
    position: fixed;
    bottom: 20px;
    left: calc(50vw - 250px);
    z-index: 2;
}

@media (max-width: 600px) {
    .routeIntro {
        left: 0;
    }
}

.sectionHowTo {
    padding: 150px 0;
}

@media (max-width: 600px) {
    .sectionHowTo {
        padding: 80px 0;
    }
}

.sectionPurchase {
    padding: 150px 0;
}

@media (max-width: 600px) {
    .sectionPurchase {
        padding: 80px 0;
    }
}

.sectionFaq {
    padding: 150px 40px 100px;
    width: 100%;
}

@media (max-width: 600px) {
    .sectionFaq {
        padding: 80px 20px 60px;
    }
}

.sectionGround {
    display: flex;
    justify-content: center;
    align-items: center;
    min-height: 100vh;
    padding-bottom: 20vh;
}

.team {
    margin-top: -50vh;
}
