.landSlider {
    position: relative;
    display: flex;
    justify-content: center;
    width: 100%;
    max-width: 400px;
}

.land1 {
    position: relative;
}

.land:not(.land1) {
    position: absolute;
}

.active {
    position: relative;
    z-index: 2;
    width: 100%;
    pointer-events: none;
}

.shadow {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    width: 100%;
    filter: hue-rotate(20deg) brightness(1.2);
    opacity: 0.3;
    pointer-events: none;
}

.land img {
    width: 100%;
}

.lock {
    position: absolute;
    top: 55%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 30%;
}

.arrow {
    position: absolute;
    opacity: 0.8;
    width: 80px;
    z-index: 3;
}

.arrow img {
    width: 100%;
}

.arrowRight {
    left: -30%;
    bottom: 32%;
    transform: rotate(-180deg);
    transform-origin: center;
}

@media (max-width: 600px) {
    .arrowRight {
        left: -5%;
    }
}

.arrowLeft {
    right: -30%;
    bottom: 31%;
}

@media (max-width: 600px) {
    .arrowLeft {
        right: -5%;
    }
}
