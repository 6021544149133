.landRoot {
    width: 100vw;
    height: 100vh;
    overflow: hidden;
    pointer-events: none;

    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
}

.landRoot:not(.display) {
    opacity: 0;
}

.emptyBase {
    position: relative;
}

.landWrapper {
    position: absolute;
    bottom: 0;
    transform-origin: bottom center;
    left: 50%;
}

.land {
    position: absolute;
    top: 0;
    left: 0;
}

.activeLand {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 2;
    transform-origin: center;
}

.inactiveLand {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 1;
    transform-origin: center;
}

.inactiveLand > .element {
    opacity: 0.3;
    filter: hue-rotate(20deg) brightness(1.2);
}

.selectArea {
    position: relative;
    /* border: 1px solid white; */
}

.selectArea.selectable {
    cursor: pointer;
    pointer-events: auto;
}

.elementWrapper {
    position: absolute;
    top: 0;
    left: 0;
}

.element {
    position: absolute;
    top: 0;
    left: 0;
}

.arrowRight {
    position: absolute;
    bottom: 38%;
    right: -30%;
    pointer-events: auto;
    cursor: pointer;
    opacity: 0.8;
}

@media (max-width: 1000px) {
    .arrowRight {
        right: 5%;
        transform: scale(1.3);
        z-index: 2;
    }
}

@media (max-width: 600px) {
    .arrowRight {
        transform: scale(1.5);
    }
}

.arrowLeft {
    position: absolute;
    bottom: 38%;
    left: -30%;
    pointer-events: auto;
    cursor: pointer;
    transform: rotate(-180deg);
    opacity: 0.8;
}

@media (max-width: 1000px) {
    .arrowLeft {
        left: 5%;
        transform: rotate(-180deg) scale(1.3);
        z-index: 2;
    }
}

@media (max-width: 600px) {
    .arrowLeft {
        transform: rotate(-180deg) scale(1.5);
    }
}
