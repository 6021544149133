@keyframes noiseFX {
    0%,
    100% {
        transform: translate(0, 0);
    }
    10% {
        transform: translate(-5%, -10%);
    }
    30% {
        transform: translate(3%, -15%);
    }
    50% {
        transform: translate(12%, 9%);
    }
    70% {
        transform: translate(9%, 4%);
    }
    90% {
        transform: translate(-1%, 7%);
    }
}

.noise {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;
    mix-blend-mode: overlay;
}

.scaleWrapper {
    position: relative;
    width: 100%;
    height: 100%;
}

.noiseSrc {
    position: absolute;
    top: -50%;
    left: -50%;
    width: 400%;
    height: 400%;
    /* animation: noiseFX 4s steps(6) infinite; */
    background-position: top;
}
