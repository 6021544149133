.intro {
    position: relative;
}

.emptySection {
    position: relative;
    width: 100vw;
    height: 100vh;
}

.content {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
}

.leadingText {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;

    display: flex;
    justify-content: center;
    align-items: center;

    color: #fff;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 700;
    line-height: 200%; /* 36px */
    letter-spacing: 1.8px;

    padding-bottom: 60px;
}

@media (max-width: 800px) {
    .leadingText {
        font-size: 16px;
    }
}

.landWrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    pointer-events: none;
}

.landSrc {
    width: calc(100vw - 40px);
    max-width: 400px;
    padding-top: 240px;
}

.landSrc > img {
    width: 100%;
}
