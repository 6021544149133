.countdown {
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-family: Inter, 'Noto Sans', 'Noto Sans TC';
    font-style: normal;
    font-weight: 700;
    line-height: 180%; /* 36px */
    letter-spacing: 0.08em;
}
