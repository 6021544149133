.skyBackground {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: calc(100vh + 60px);
}

.noiseWrapper {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    mix-blend-mode: overlay;
}

.starLayer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 160%;
    opacity: 0.4;
}

.cloudEnterLayer {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.cloud {
    position: absolute;
}

.landSrc {
    position: absolute;
    width: 500px;
    bottom: -50vh;
    left: 50vw;
    transform: translate(-50%, -50%);
}

.landSrc > img {
    width: 100%;
}

.gradient {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    mix-blend-mode: soft-light;
    transform-origin: bottom center;
}

.gradient > img {
    width: 100%;
}

.ground {
    display: flex;
    position: absolute;
    bottom: 0;

    justify-content: center;
}

.ground > img {
    width: 100%;
}

.ground.mobile > img {
    width: 150%;
}

.groundMask {
    position: absolute;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #271e1e;
    mix-blend-mode: soft-light;
}

.groundShadow {
    position: absolute;
    left: 0;
    width: 100%;
    height: 49px;
    background: linear-gradient(0deg, rgba(10, 10, 10, 0) 46.5%, rgba(39, 30, 30, 0.2) 100%);
    mix-blend-mode: overlay;
}
