.mobileMenu {
    position: fixed;
    width: 100vw;
    height: 110vh;
    background-color: #303dea;
    left: 0;
}

.container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100vw;
}

.navItem {
    color: #fff;
    font-family: Inter, 'Noto Sans', 'Noto Sans TC', 'PingFangTC', 'Microsoft JhengHei', sans-serif;
    font-size: 15px;
    font-weight: 700;
    line-height: 180%;
    letter-spacing: 0.05em;
    flex-shrink: 0;
    margin-bottom: 20px;
}

.close {
    position: fixed;
    top: 48px;
    right: 20px;
    color: white;
}
