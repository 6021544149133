.app {
}

.mainContainer {
    position: relative;
    width: 100vw;
}

.emptySection {
    width: 100%;
    height: 100vh;
}
