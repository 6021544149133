.drawerItem {
    position: relative;
}

.label {
    display: flex;
    justify-content: space-between;
    padding: 20px 0;

    color: white;
    font-size: 16px;
    font-style: normal;
    font-weight: 600;
    letter-spacing: 0.05em;
    line-height: 180%; /* 19.5px */

    cursor: pointer;
}

@media (max-width: 1000px) {
    .label {
        font-size: 15px;
    }
}

.arrow {
    padding: 0 12px;
}

.arrow.open {
    transform-origin: center;
    transform: rotate(180deg);
}

.container {
    position: relative;
    overflow: hidden;
}

.content {
    position: relative;
    width: 100%;
    top: 0;
    left: 0;

    padding-bottom: 36px;
    color: white;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.05em;
    line-height: 200%; /* 22.23px */

    white-space: pre-line;
}

@media (max-width: 1000px) {
    .content {
        font-size: 13px;
    }
}

.content strong {
    color: #c9ffa3;
}

.content a {
    text-decoration: underline;
    text-underline-offset: 6px;
}

.itemLink {
    color: #c9ffa3;
    cursor: pointer;
}

.content ol,
.content ul {
    padding-left: 28px;
    white-space: normal;
}
