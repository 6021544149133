.countdownMainSection {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    filter: brightness(2);
}

.intro {
    color: #fff;
    text-align: center;
    font-size: 14px;
    font-style: normal;
    font-weight: 500;
    line-height: 180%; /* 25.2px */
    max-width: 500px;
    margin-top: 40px;
    letter-spacing: 0.05em;
    padding: 0 12px;
}

.countdownGroup {
    margin-top: 60px;
}

.countdownGroup > .label {
    color: #fff;
    text-align: center;
    font-size: 12.5px;
    font-style: normal;
    font-weight: 700;
    line-height: 180%; /* 21.6px */
    letter-spacing: 0.08em;
}

.countdownGroup > .countdown {
    color: #fff;
    text-align: center;
    font-size: 20px;
    font-family: Inter, 'Noto Sans', 'Noto Sans TC';
    font-style: normal;
    font-weight: 700;
    line-height: 180%; /* 36px */
    letter-spacing: 0.08em;
}

.btn {
    display: inline-flex;
    padding: 8px 32px;
    justify-content: center;
    align-items: center;
    border-radius: 56px;
    background: #fff;

    color: #1c1d37;
    text-align: center;
    font-size: 18px;
    font-style: normal;
    font-weight: 900;
    line-height: 180%; /* 36px */
    letter-spacing: 0.08em;

    margin-top: 22px;
}
