.title {
    margin-bottom: 30px;
}

.content {
    color: white;
    font-size: 15px;
    font-style: normal;
    text-align: center;
    font-weight: 600;
    line-height: 180%; /* 23.8px */
    letter-spacing: 0.03em;
}

.iconGroup {
    display: flex;
    justify-content: center;
    margin-top: 20px;
}

.icon {
    margin: 0 10px;
    cursor: pointer;
}
