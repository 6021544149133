.layoutAboutLove {
    position: relative;
    /* min-height: calc(100vh); */

    color: white;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.05em;
    line-height: 160%; /* 22.23px */
}

@media (max-width: 1000px) {
    .layoutAboutLove {
        font-size: 14px;
    }
}

.title {
    margin-bottom: 36px;
}

.tabs {
    margin: 0 auto 24px;
}

.container {
    position: relative;
}

.intro {
    font-size: 15px;
    position: relative;
    max-width: 620px;
    margin: 0 auto;
}

@media (max-width: 1000px) {
    .intro {
        font-size: 14px;
    }
}

.itemWrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 60px;
}

.item {
    display: flex;
    width: 260px;
    margin-bottom: 6px;
}

.item .name {
    display: flex;
    justify-content: flex-end;
    width: 100px;
    margin-right: 6px;
}

.item .value {
    width: calc(100% - 100px);
    margin-left: 6px;
}

.timeContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 80px auto 0;
}

.timeContainer * {
    white-space: nowrap;
}

.timeTitle {
    text-align: center;
    margin-left: -40px;
    margin-bottom: 12px;
}

.time {
    width: 230px;
    text-align: left;
}

.deleted {
    text-decoration: line-through;
}

.time .note {
    font-size: 12px;
    position: absolute;
}

.emptyBlock {
    height: 100px;
}

.bottom {
    display: flex;
    position: absolute;
    width: 800px;
    top: 0;
    left: 50%;
    transform: translate(-50%, 0);
    user-select: none;
}

.bottom img {
    width: 100%;
}

.btn {
    display: block;
    text-align: center;
    margin: 60px auto 0;
}
