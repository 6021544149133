.enter {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.title {
    width: calc(190px * 1.5);
    height: calc(55px * 1.5);
}

@media (max-width: 800px) {
    .title {
        width: calc(190px * 1);
        height: calc(55px * 1);
    }
}

.subtitle {
    color: #fff;
    text-align: center;
    font-size: 15px;
    font-weight: 600;
    letter-spacing: 2.4px;
    margin-top: 10px;
}

@media (max-width: 800px) {
    .subtitle {
        font-size: 12px;
        margin-top: 6px;
    }
}

.btn {
    margin-top: 24px;
}
