.staticCredits {
    color: white;
    padding: 120px 0;
}

.sectionTitle {
    margin-bottom: 8px;
}

.group {
    width: 100vw;
    transition: 0.3s ease-in-out;
}

.titleWrapper:not(.noPadding) {
    padding-top: 100px;
}

.noPadding {
    padding-top: 60px;
}

.title {
    color: white;
    font-family: Inter, 'Noto Sans', 'Noto Sans TC', 'PingFangTC', 'Microsoft JhengHei';
    font-size: 20px;
    font-weight: 600;
    text-align: center;
    line-height: normal;
    letter-spacing: 0.05em;
}

@media (max-width: 800px) {
    .title {
        font-size: 16px;
    }
}

.note {
    color: white;
    font-family: Inter, 'Noto Sans', 'Noto Sans TC', 'PingFangTC', 'Microsoft JhengHei';
    font-size: 13px;
    font-weight: 600;
    text-align: center;
    line-height: normal;
    letter-spacing: 0.05em;
}

.roleContainer {
    margin: 0 auto;
    width: 100%;
    max-width: 600px;
    padding: 0 20px;
}

.role {
    display: flex;
    position: relative;
    justify-content: center;
    width: 100%;

    color: white;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.05em;
    line-height: 200%;
}

@media (max-width: 800px) {
    .role {
        font-size: 13px;
    }
}

.role.inline .roleTitle {
    width: 50%;
}

.role:not(.inline) .roleTitle {
    display: flex;
    justify-content: center;
    width: 100%;
    text-align: center;
}

.role.inline .roleMemberContainer {
    width: 50%;
}

.roleTitle {
    display: flex;
    justify-content: flex-end;
    text-align: right;
}

.member {
    color: white;
    font-size: 15px;
    font-weight: 500;
    letter-spacing: 0.05em;
    line-height: 200%;
    text-align: center;
}

@media (max-width: 800px) {
    .member {
        font-size: 13px;
    }
}

.member.multirow {
    display: flex;
    justify-content: center;
    width: 100px;
    white-space: nowrap;
}

@media (max-width: 800px) {
    .member.multirow {
        width: 60px;
    }
}

.memberContainer {
    width: 100%;
    max-width: 500px;
}

@media (max-width: 800px) {
    .memberContainer {
        max-width: 200px;
    }
}

.end {
    color: white;
    font-size: 13px;
    font-weight: 500;
    letter-spacing: 0.05em;
    padding-top: 20px;
    text-align: center;
    padding: 20px;
    line-height: 1.71;
}

.group .produceTeam {
    justify-content: flex-start;
}

.group .research {
    justify-content: flex-start;
}
