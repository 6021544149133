.rocketGroup {
    pointer-events: none;

    position: fixed;
    left: calc(50vw - 50px);
    bottom: 0;

    display: flex;
    width: 100px;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    transform-origin: bottom center;
}

.hint {
    display: flex;
    align-items: flex-end;
    color: #fff;
    text-align: center;
    font-family: Montserrat;
    font-size: 13px;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    letter-spacing: 2.6px;
    overflow: hidden;
}
