@import 'normalize.css';
@import url('https://fonts.googleapis.com/css2?family=DM+Serif+Text:ital@0;1&family=Noto+Sans+TC:wght@300;400;500;600;700;800&family=Noto+Sans:wght@300;400;500;600;700;800&family=Noto+Serif+Display&family=Noto+Serif+TC:wght@400;500;700&family=Noto+Serif:wght@400;500;700&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100..900&family=Montserrat:ital,wght@0,100..900;1,100..900&display=swap');

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
}

html,
body {
    box-sizing: border-box;
    font-family: Montserrat, 'Noto Sans', 'Noto Sans TC', 'PingFangTC', 'Microsoft JhengHei',
        sans-serif;
    outline: none;
    max-width: 100vw;
    overflow-x: hidden;
    background-color: #0a0b16;
}

.lock {
    overflow: hidden;
}

a {
    color: inherit;
    text-decoration: none;
}
